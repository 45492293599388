<template>
<div>
    <div class="pb-2">
        <TheHeader />

    </div>
    <div class="main-background">
        <div class="about-image">
            <CContainer class="pt-5">
                <CRow class="m-3 mt-5">
                    <CCol md="6">
                        <div class="">
                           <h2 class="text-white">
                        We bring your favourite <br />
                        restaurants at your fingertips<br />
                        <b class="heading-txt-color">Exceptional Dining Experience Starts Here...</b><br />
                    </h2>
                        </div>
                    </CCol>
                    <CCol md="6">

                        <p class="text-white ">
                            <b>SCANME</b><br>
                            2021 gave birth to ScanMe inspired by innovative digital technologies and design thinking to
                            revolutionize the food industry through smart dining & smart reservation concepts. We focus on
                            high user experience and efficient operations in reservations and dining. ScanMe enables end
                            to end contactless reservations and dining experience with innovative restaurant management
                            solutions
                        </p>
                    </CCol>

                </CRow>
            </CContainer>
        </div>

        <div class="bg-color pt-4 pb-5">
            <CContainer>
            <CRow>
                    <CCol md="12">
                        <h1 class="text-center pb-4">Our Vision & Values</h1>
                    </CCol>
                    <CCol md="6">
                        <h3>Vision</h3>
                        <p>
                            Scan your aspirations for a digital future that fulfills all your needs through innovation.
                        </p>

                    </CCol>
                    <CCol md="6">
                        <h3>Values</h3>
                        <p>
                            <b class="values-txt-color">C</b>ommitted for excellence
                        </p>
                         <p>
                            <b class="values-txt-color">L</b>everage on innovative technologies
                        </p>
                        <p>
                            <b class="values-txt-color">E</b>xceptional service
                        </p>
                          <p>
                           <b class="values-txt-color">A</b>ppreciate feedback for continuous improvement
                        </p>
                         <p>
                           <b class="values-txt-color">R</b>eliable and secured platform
                        </p>
                    </CCol>
            </CRow>
            </CContainer>
        </div>

        <div class="bg-image">
            <CContainer class="pt-5">
                <CRow class="justify-content-center pt-5">
                    <CCol md="8">
                        <CCard class="mt-5 ceo-bg-color">
                            <h1 class="text-center  pt-4">A Letter From Our CEO</h1>
                            <p class=" pl-5 pr-5 pb-3 pt-3">
                                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et
                                dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet
                                clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet,
                                consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
                                sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea
                                takimata sanctus est Lorem ipsum dolor sit amet.

                            </p>
                        </CCard>
                    </CCol>
                </CRow>
            </CContainer>
        </div>

    </div>

    <div>
        <TheFooter />

    </div>

</div>
</template>

<script>
import TheHeader from '../../../containers/TheHeader.vue'
import TheFooter from '../../../containers/TheFooter.vue'

export default {
    components: {
        TheHeader,
        TheFooter
    }
}
</script>

<style scoped>

.values-txt-color{
    color: #de98ff;
    font-size: 1.2em;
    padding-right: 1px;
}
.bg-color {
    background-color: #09092F;
    color: #ffffff;
}
.ceo-bg-color{
    background-color: #00000088;
    color: #ffffff; 
}
.main-background {
    background-color: black;
}

.about-image {
    background-image: url('../../../../public/img/bg_images/about-img.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.bg-image {
    background-image: url('../../../../public/img/bg_images/bg_image.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 70vh;
}
</style>
